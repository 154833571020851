import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import {HTMLContent} from '../components/Content'
import Contactgegevens from "../components/Contactgegevens";
import Adres from "../components/Adres";
import Openingsuren from "../components/Openingsuren";


export const ContactPageTemplate = ({titel, contactgegevens, adres, openingsuren, info}) => {
    return (
        <div>
            <section className="basic-header">
                <h1>{titel}</h1>
            </section>
            <section className="section-contact">
                <div className="container">
                    <div className="columns">
                        <div className="column contact-form">
                            <h4 className="contact-item-title" style={{marginBottom: '20px'}}>Vragen?</h4>
                            <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
                                <input type="hidden" name="bot-field" />
                                <input type="hidden" name="form-name" value="contact" />
                                <input className="input-beweegpunt" name="name" type="text" placeholder="Naam"/>
                                <input className="input-beweegpunt" name="email" type="email" placeholder="E-mailadres"/>
                                <textarea className="input-beweegpunt" name="message" rows="4" cols="50" placeholder="Bericht"></textarea>
                                <button type="submit" className="btn-contact" style={{display: 'block',
                                        width: '200px',
                                        textAlign: 'center',
                                        margin: 0,
                                        cursor: 'pointer',
                                        fontSize: '1rem',
                                        backgroundColor: '#0FA2EF',
                                        color: '#fff',
                                        borderRadius: '20px',
                                        border: 'none',
                                        height: '40px'}}>
                                    Verzend
                                </button>
                            </form>
                        </div>
                        <div className="column contact-content">
                            <Contactgegevens contactgegevens={contactgegevens}/>
                            <a href="https://www.digitalewachtkamer.be/privacymelding.aspx" target="_blank" rel="noopener noreferrer">Privacymelding</a>
                            <Adres adres={adres}/>
                            <Openingsuren openingsuren={openingsuren}/>
                            <div className="contact-item info">
                                <h4 className="contact-item-title">Info</h4>
                                <div className="contact-item-content">
                                  <HTMLContent content={info} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <iframe title="Locatie" src={'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2503.8857497183394!2d4.5775625!3d51.1290154!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd71909accac828e3!2sBeweegpunt!5e0!3m2!1sen!2sbe!4v1566840643044!5m2!1sen!2sbe'}
                        width="600" frameBorder="0" style={{border:0, height: '500px', width: '100%'}} allowFullScreen={true}></iframe>
            </section>
        </div>
    )
}

// ContactPageTemplate.propTypes = {
//     title: PropTypes.string.isRequired,
//     content: PropTypes.string,
//     contentComponent: PropTypes.func,
// }

const ContactPage = ({data}) => {
    const {markdownRemark: post} = data;
    return (
        <Layout noFooter={true}>
            <ContactPageTemplate
              titel={post.frontmatter.title}
              adres={post.frontmatter.adres}
              contactgegevens={post.frontmatter.contactgegevens}
              openingsuren={post.frontmatter.openingsuren}
              info={post.frontmatter.info}
            />
        </Layout>
    )
}

ContactPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default ContactPage

export const contactPageQuery = graphql`
  query ContactPage {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        title
        adres
        contactgegevens
        openingsuren
        info
      }
    }
  }
`
